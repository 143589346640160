<template>
<div class="letwecare-courses-wrapper">
  <template v-if="!loading && data.length">
    <a-row :gutter="24">
      <a-col
        v-for="item in data"
        :lg="6" :md="12" :xs="24"
      >
        <div class="course-card" @click="toCoursePage(item.id)">
          <a-row>
            <a-col :span="24">
              <div class="card-title ellipsis-1">{{ item.name }}</div>

              <div class="card-info">
                <template v-if="item.coaches.length">
                  教練：{{ item.coaches.map((elem) => elem.name ).join(",") }}
                </template>
                <template v-else>
                  尚未排定教練
                </template>
              </div>

              <template v-if="item.coaches.length > 0">
                <a-avatar
                  class="card-avatar"
                  :src="item.coaches[0].avatar!=null ? item.coaches[0].avatar.uri : require('@/assets/icons/avatar.png')"
                  size="large"
                  :style="{ position: 'absolute', right: '4px', bottom: '18px' }"
                />
              </template>
            </a-col>
          </a-row>
          
          <div class="card-stat">
            <a-row>
              <a-col :span="8" align="center">
                <AppstoreOutlined class="mr-1" /> {{ item.counter.lession }}
              </a-col>
              <a-col :span="8" align="center">
                <PictureOutlined class="mr-1" /> {{ item.counter.image }}
              </a-col>
              <a-col :span="8" align="center">
                <TeamOutlined class="mr-1" /> {{ item.counter.participant }}
              </a-col>
            </a-row>
          </div>

          <div class="card-progress">
            <a-progress :percent="item.completed" :show-info="false" />
          </div>

          <div class="card-meta">
            <a-row>
              <a-col :span="12">
                完成度 <span :style="{ color: 'black' }">{{ item.completed }}%</span>
              </a-col>
              <a-col :span="12" align="right" v-if="item.completed < 100 && item.lessions.length > 0">
                上課日 <span :style="{ color: 'black' }">{{ moment(item.upcomingDatetime).format('MM/DD') }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>
  </template>

  <template v-if="!loading && !data.length">
    <a-empty :image="simpleImage" description="空的" />
  </template>

  <template v-if="loading">
    <div :style="{ textAlign: 'center', height: '200px', lineHeight: '200px' }">
      <a-spin
        :indicator="indicator"
        tip="努力載入中"
      />
    </div>
  </template>
</div>
</template>

<script>
import { defineComponent, h } from 'vue'
import { Empty } from 'ant-design-vue'
import { FileTextOutlined, ScheduleOutlined, AppstoreOutlined, PictureOutlined, TeamOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import moment from 'moment'
import { useAuthStore } from '@/store/auth'

export default defineComponent({
  props: ['data', 'loading'],
  components: {
    FileTextOutlined,
    ScheduleOutlined,
    AppstoreOutlined,
    PictureOutlined,
    TeamOutlined,
    LoadingOutlined
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
      moment,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      /* Antd */
      indicator: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      })
    }
  },
  methods: {
    /* router */
    toCoursePage(course_id) {
      this.$router.push({
        name: 'AppCoursePage',
        params: {
          course_id: course_id
        }
      });
    }
  }
})
</script>