<template>
  <a-modal
    :wrap-class-name="{ 'full-modal': device.mobile }"
    :visible="visible"
    title="創建班級"
    :maskClosable="false"
    :style="{ top: device.mobile ? '0px' : '40px' }"
  >
    <!-- Form -->
    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="班級期別"
            name="semester"
            :rules="[{ required: true, message: '請選擇班級期別' }]"
          >
            <a-select
              v-model:value="formState.semester"
              :options="semesterOptions"
              :disabled="loading"
              size="large"
            ></a-select>
          </a-form-item>
        </a-col>
      </a-row>
      
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="班級名稱"
            name="name"
            :rules="[{ required: true, message: '請輸入班級名稱' }]"
          >
            <a-input
              v-model:value="formState.name"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="班級簡介"
            name="description"
            :rules="[{ required: true, message: '請輸入班級簡介' }]"
          >
            <a-input
              v-model:value="formState.description"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="教室名稱"
            name="classroom"
            :rules="[{ required: true, message: '請輸入教室名稱' }]"
          >
            <a-input
              v-model:value="formState.classroom"
              :disabled="loading"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider>班級上課時間</a-divider>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="班級開課日期區間"
            name="end_date"
            :rules="[{ required: true, message: '請選擇班級開課日期區間' }]"
          >
            <a-range-picker
              v-model:value="tempDateRange"
              @change="onChangeDateRange"
              format="YYYY-MM-DD"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="每週上課日"
            name="days"
            :rules="[{ required: true, message: '請選擇上課日' }]"
          >
            <a-checkbox-group
              v-model:value="formState.days"
              :options="dayOptions"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item
            label="課堂開始時間"
            name="start_time"
            :rules="[{ required: true, message: '請選擇課堂開始時間' }]"
          >
            <a-time-picker
              v-model:value="tempStartTime"
              @change="onChangeStartTime"
              format="HH:mm"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="課堂結束時間"
            name="end_time"
            :rules="[{ required: true, message: '請選擇課堂結束時間' }]"
          >
            <a-time-picker
              v-model:value="tempEndTime"
              @change="onChangeEndTime"
              format="HH:mm"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- 是否依照課堂時間自動創建課堂 -->
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="是否依照課堂時間自動創建課堂"
            name="auto_create_lessions"
          >
            <a-checkbox v-model:checked="auto_create_lessions">自動創建課堂</a-checkbox>
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider>班級助教及教練</a-divider>
      
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="班級助教"
          >
            <a-select
              v-model:value="formState.assistant_ids"
              :options="assistantsData"
              :filterOption="memberFilteredOptions"
              :disabled="loading"
              mode="multiple"
              size="large"
              showSearch
              allowClear
              optionLabelProp="label"
              placeholder="輸入姓名或助教編號"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="班級教練"
          >
            <a-select
              v-model:value="formState.coach_ids"
              :options="coachesData"
              :filterOption="memberFilteredOptions"
              :disabled="loading"
              mode="multiple"
              size="large"
              showSearch
              allowClear
              optionLabelProp="label"
              placeholder="輸入姓名或教練編號"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- Form -->
    
    <a-typography-text
      type="secondary"
      :style="{ display: 'block', textAlign: 'right' }"
    >詳細資訊可以在創建班級後進行設定</a-typography-text>

    <template #footer>
      <a-button key="cancel" :disabled="loading" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">確認創建班級</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useSemesterStore } from '@/store/semester'
import dayjs from 'dayjs'
import api from '@/apis'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const device = createDeviceDetector();

    /* Store */
    const semesterStore = useSemesterStore();
    
    /* Loading */
    const loading = ref(false);

    /* Data */
    const assistantsData = ref([]);
    const coachesData = ref([]);

    /* Api */
    const getAssistantsData = (async () => {
      assistantsData.value = [];
      
      const response = await api.v1.assistant.list();
      const result = response.data.result;
      
      result.forEach(item => {
        assistantsData.value.push({
          label: item.name,
          value: item.member_id
        });
      });
    });

    const getCoachesData = (async () => {
      coachesData.value = [];
      
      const response = await api.v1.coach.list();
      const result = response.data.result;
      result.forEach(item => {
        coachesData.value.push({
          label: item.name,
          value: item.member_id
        });
      });
    });

    /* Form State */
    const initialState = {
      semester: semesterStore.currentSemesterYear,
      name: '',
      description: '',
      classroom: '',
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      assistant_ids: [],
      coach_ids: []
    }
    const auto_create_lessions = ref(false);
    const formState = reactive({ ...initialState });
    function resetForm() {
      Object.assign(formState, initialState);
    }

    /* Options */
    // semester
    const semesterOptions = ref([]);
    // days
    const dayOptions = ref([{
      label: '禮拜ㄧ',
      value: '1'
    }, {
      label: '禮拜二',
      value: '2'
    }, {
      label: '禮拜三',
      value: '3'
    }, {
      label: '禮拜四',
      value: '4'
    }, {
      label: '禮拜五',
      value: '5'
    }, {
      label: '禮拜六',
      value: '6'
    }, {
      label: '禮拜日',
      value: '7'
    }]);
    // member
    function memberFilteredOptions(input, option) {
      return option.label.includes(input) || option.value.includes(input)
    }

    /* Mounted */
    onMounted(async () => {
      await getAssistantsData();
      await getCoachesData();
      semesterOptions.value = await semesterStore.getOptions();
    });

    /* Datetime Picker */
    const tempDateRange = ref('');
    const onChangeDateRange = (value) => {
      formState.start_date = dayjs(value[0]).format('YYYY-MM-DD');
      formState.end_date = dayjs(value[1]).format('YYYY-MM-DD');
    }
    const tempStartTime = ref('');
    const tempEndTime = ref('');
    const onChangeStartTime = (value) => {
      formState.start_time = dayjs(value).format('HH:mm');
    }
    const onChangeEndTime = (value) => {
      formState.end_time = dayjs(value).format('HH:mm');
    }

    return {
      device,

      /* Store */
      semesterStore,

      /* Loading */
      loading,

      /* Data */
      assistantsData,
      coachesData,

      /* FormState */
      formState,
      auto_create_lessions,
      resetForm,

      /* Options */
      semesterOptions,
      dayOptions,
      memberFilteredOptions,

      /* Datetime Picker */
      tempDateRange,
      onChangeDateRange,
      tempStartTime,
      tempEndTime,
      onChangeStartTime,
      onChangeEndTime,

      /* Api */
      api
    }
  },
  methods: {
    async handleOk() {
      const err = this._checkRequried();
      if (err) {
        message.error(err);
        return
      }
      
      /* Create course */
      this.loading = true;
      let courseId = undefined;

      try {
        const courseCreateResp = await this.api.v1.course.create({
          data: this.formState,
          auto_create_lessions: {
            auto_create_lessions: this.auto_create_lessions
          }
        });
        courseId = courseCreateResp.data.result.id;
      } catch (error) {
        console.log(error);
        message.error('創建班級發生錯誤');
        
        this.handleCancel();
        this.loading = false; 
        return
      }

      /* Join course's assistant & coach  */
      const joinAssistantParmas = {
        course_id: courseId,
        ids: this.formState.assistant_ids
      }
      const joinCoachParmas = {
        course_id: courseId,
        ids: this.formState.coach_ids
      }

      try {
        await this.api.v1.course.joinMembers('assistant', joinAssistantParmas);
        await this.api.v1.course.joinMembers('coach', joinCoachParmas);
        message.success('創建班級成功');
      } catch (error) {
        console.log(error);
        message.error('創建班級：加入助教及教練發生錯誤');

        this.handleCancel();
        this.loading = false; 
        return
      }

      this.resetForm();
      this.$emit('confirm');
      this.loading = false;
    },
    async handleCancel() {
      this.$emit('cancel');
      
      // reset data
      this.resetForm();
      this.semesterOptions = await this.semesterStore.getOptions();
    },

    /* Private func */
    _checkRequried() {
      let err = '';
      
      if (!this.formState.name) {
        err = '請輸入班級名稱';
      }
      else if (!this.formState.description) {
        err = '請輸入班級簡介';
      }
      else if (!this.formState.classroom) {
        err = '請輸入教室名稱';
      }
      else if (!this.formState.start_date || !this.formState.end_date) {
        err = '請輸入開課日期區間';
      }
      else if (this.formState.days.length == 0) {
        err = '請輸入每週上課日';
      }
      else if (!this.formState.start_time) {
        err = '請輸入課堂開始時間';
      }
      else if (!this.formState.end_time) {
        err = '請輸入課堂結束時間';
      }
      // else if (this.formState.assistant_ids.length == 0) {
      //   err = '請選擇班級助教';
      // }
      // else if (this.formState.coach_ids.length == 0) {
      //   err = '請選擇班級教練';
      // }

      return err
    }
  }
})
</script>