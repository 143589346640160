<template>
  <a-modal
    :wrap-class-name="['create-course-from-template-modal', { 'full-modal': device.mobile }]"
    :visible="visible"
    title="從班級匯入"
    :maskClosable="false"
    @cancel="handleCancel"
    :style="{ top: device.mobile ? '0px' : '40px' }"
  >
    <!-- Step 1. 選擇班級模板 -->
    <template v-if="step == 1">
      <a-typography-title :level="5">Step 1. 選擇班級模板</a-typography-title>

      <div class="my-5">
        <a-select
          v-model:value="semesterValue"
          :options="semesterOptions"
          @change="changeSemester"
          :style="{ width: '120px', marginBottom: '12px' }"
        />

        <a-list
          item-layout="horizontal"
          :data-source="coursesData"
          :style="{ maxHeight: '50vh', overflow: 'auto' }"
        >
          <template #renderItem="{ item }">
            <a-list-item>
              <a-list-item-meta
                :description="item.description"
              >
                <template #title>
                  {{ item.name }}
                </template>
              </a-list-item-meta>

              <template #actions>
                <a-button type="primary" size="small" @click="chooseCourseTemplate(item)">選擇</a-button>
              </template>
            </a-list-item>
          </template>
        </a-list>
      </div>
    </template>
    <!-- Step 1. 選擇班級模板 -->

    <!-- Step 2. 設定班級內容 -->
    <template v-if="step == 2">
      <a-typography-title class="mb-5" :level="5">
        <a-button
          @click="backToStep1"
          type="link" shape="circle" size="small"
        >
          <template #icon>
            <span class="mdi mdi-arrow-left" :style="{ fontSize: '1rem', color: '#212121' }" />
          </template>
        </a-button>
        Step 2. 設定班級
      </a-typography-title>

      <!-- Form -->
      <a-form
        :model="formState"
        layout="vertical"
        autocomplete="off"
      >
        <a-row>
          <a-col :span="24">
            <a-form-item
              label="班級期別"
              name="semester"
              :rules="[{ required: true, message: '請選擇班級期別' }]"
            >
              <a-select
                v-model:value="formState.semester"
                :options="semesterOptions"
                :disabled="loading"
                size="large"
              ></a-select>
            </a-form-item>
          </a-col>
        </a-row>
        
        <a-row>
          <a-col :span="24">
            <a-form-item
              label="班級名稱"
              name="name"
              :rules="[{ required: true, message: '請輸入班級名稱' }]"
            >
              <a-input
                v-model:value="formState.name"
                :disabled="loading"
                size="large"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <a-form-item
              label="班級簡介"
              name="description"
              :rules="[{ required: true, message: '請輸入班級簡介' }]"
            >
              <a-input
                v-model:value="formState.description"
                :disabled="loading"
                size="large"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <a-form-item
              label="教室名稱"
              name="classroom"
              :rules="[{ required: true, message: '請輸入教室名稱' }]"
            >
              <a-input
                v-model:value="formState.classroom"
                :disabled="loading"
                size="large"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider>班級上課時間</a-divider>

        <a-row>
          <a-col :span="24">
            <a-form-item
              label="班級開課日期區間"
              name="end_date"
              :rules="[{ required: true, message: '請選擇班級開課日期區間' }]"
            >
              <a-range-picker
                v-model:value="tempDateRange"
                @change="onChangeDateRange"
                format="YYYY-MM-DD"
                size="large"
                :style="{ width: '100%' }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <a-form-item
              label="每週上課日"
              name="days"
              :rules="[{ required: true, message: '請選擇上課日' }]"
            >
              <a-checkbox-group
                v-model:value="formState.days"
                :options="dayOptions"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              label="課堂開始時間"
              name="start_time"
              :rules="[{ required: true, message: '請選擇課堂開始時間' }]"
            >
              <a-time-picker
                v-model:value="tempStartTime"
                @change="onChangeStartTime"
                format="HH:mm"
                size="large"
                :style="{ width: '100%' }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="課堂結束時間"
              name="end_time"
              :rules="[{ required: true, message: '請選擇課堂結束時間' }]"
            >
              <a-time-picker
                v-model:value="tempEndTime"
                @change="onChangeEndTime"
                format="HH:mm"
                size="large"
                :style="{ width: '100%' }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider>課堂列表</a-divider>

        <a-row>
          <a-col :span="24">
            <a-list
              item-layout="horizontal"
              :data-source="lessionsState"
            >
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta
                    :description="item.description"
                  >
                    <template #title>
                      {{ item.name }}
                    </template>
                    <template #avatar>
                      <a-date-picker v-model:value="item.tempStartDate" />
                    </template>
                    <!-- <template #description>
                      <a-input
                        v-model:value="item.description"
                        placeholder="請輸入課堂說明"
                      />
                    </template> -->
                  </a-list-item-meta>
                </a-list-item>
              </template>
            </a-list>
          </a-col>
        </a-row>

        <a-divider>班級助教及教練</a-divider>
      
        <a-row>
          <a-col :span="24">
            <a-form-item
              label="班級助教"
            >
              <a-select
                v-model:value="formState.assistant_ids"
                :options="assistantsData"
                :filterOption="memberFilteredOptions"
                :disabled="loading"
                mode="multiple"
                size="large"
                showSearch
                allowClear
                optionLabelProp="label"
                placeholder="輸入姓名或助教編號"
                :style="{ width: '100%' }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <a-form-item
              label="班級教練"
            >
              <a-select
                v-model:value="formState.coach_ids"
                :options="coachesData"
                :filterOption="memberFilteredOptions"
                :disabled="loading"
                mode="multiple"
                size="large"
                showSearch
                allowClear
                optionLabelProp="label"
                placeholder="輸入姓名或教練編號"
                :style="{ width: '100%' }"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- Form -->
    </template>
    <!-- Step 2. 設定班級內容 -->

    <template #footer>
      <template v-if="step == 2">
        <a-button key="cancel" :disabled="loading" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">確認創建班級</a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useAuthStore } from '@/store/auth'
import { useSemesterStore } from '@/store/semester'
import dayjs from 'dayjs'
import customParseFormat from "dayjs/plugin/customParseFormat"
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'

dayjs.extend(customParseFormat);

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();
    const semesterStore = useSemesterStore();

    /* Loading */
    const loading = ref(false);

    /* Step */
    const step = ref(1);

    /* Form State */
    const initialState = {
      semester: semesterStore.currentSemesterYear,
      name: '',
      description: '',
      classroom: '',
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      assistant_ids: [],
      coach_ids: []
    }
    const formState = reactive({ ...initialState });
    const lessionsState = ref([]);
    function resetForm() {
      Object.assign(formState, initialState);
    }

    /* Data */
    const coursesData = ref([]);
    const assistantsData = ref([]);
    const coachesData = ref([]);

    /* Year Select */
    const semesterValue = ref(semesterStore.currentSemesterYear);

    /* Datetime Picker */
    const tempDateRange = ref([]);
    const onChangeDateRange = (value) => {
      formState.start_date = dayjs(value[0]).format('YYYY-MM-DD');
      formState.end_date = dayjs(value[1]).format('YYYY-MM-DD');
    }
    const tempStartTime = ref('');
    const tempEndTime = ref('');
    const onChangeStartTime = (value) => {
      formState.start_time = dayjs(value).format('HH:mm');
    }
    const onChangeEndTime = (value) => {
      formState.end_time = dayjs(value).format('HH:mm');
    }

    /* Options */
    // semester
    const semesterOptions = ref([]);
    // days
    const dayOptions = ref([{
      label: '禮拜ㄧ',
      value: '1'
    }, {
      label: '禮拜二',
      value: '2'
    }, {
      label: '禮拜三',
      value: '3'
    }, {
      label: '禮拜四',
      value: '4'
    }, {
      label: '禮拜五',
      value: '5'
    }, {
      label: '禮拜六',
      value: '6'
    }, {
      label: '禮拜日',
      value: '7'
    }]);
    // member
    function memberFilteredOptions(input, option) {
      return option.label.includes(input) || option.value.includes(input)
    }

    /* Api */
    const getCoursesData = (async () => {
      coursesData.value = [];
      
      let response = undefined;
      if (authStore.info.role == 4) {
        response = await api.v1.course.list({ semester: semesterValue.value });
      } else {
        response = await api.v1.course.belongList(
          authStore.info.uid,
          {
            role: authStore.info.role,
            semester: semesterValue.value
          }
        );
      }

      coursesData.value = response.data.result;

      console.log(response.data.result)
    });

    const getAssistantsData = (async () => {
      assistantsData.value = [];
      
      const response = await api.v1.assistant.list();
      const result = response.data.result;
      
      result.forEach(item => {
        assistantsData.value.push({
          label: item.name,
          value: item.member_id
        });
      });
    });

    const getCoachesData = (async () => {
      coachesData.value = [];
      
      const response = await api.v1.coach.list();
      const result = response.data.result;
      result.forEach(item => {
        coachesData.value.push({
          label: item.name,
          value: item.member_id
        });
      });
    });

    /* Mounted */
    onMounted(async () => {
      await getAssistantsData();
      await getCoachesData();
      semesterOptions.value = await semesterStore.getOptions();
    });

    return {
      device,
      semesterStore,

      /* Semester Options */
      semesterValue,

      /* Options */
      semesterOptions,
      dayOptions,
      memberFilteredOptions,

      /* Step */
      step,

      /* Loading */
      loading,

      /* Datetime Picker */
      tempDateRange,
      onChangeDateRange,
      tempStartTime,
      tempEndTime,
      onChangeStartTime,
      onChangeEndTime,

      /* FormState */
      formState,
      lessionsState,
      resetForm,

      /* Data */
      coursesData,
      assistantsData,
      coachesData,

      /* Api */
      api,
      getCoursesData
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.getCoursesData();
      }
    }
  },
  methods: {
    /* Semester */
    changeSemester() {
      this.getCoursesData();
    },

    /* Process Flow Functions */
    async backToStep1() {
      this.resetForm();
      this.semesterOptions = await this.semesterStore.getOptions();
      this.step = 1;
    },
    chooseCourseTemplate(course) {
      console.log(course);

      this.formState.name = course.name;
      this.formState.description = course.description;
      this.formState.classroom = course.classroom;
      
      // set datetime
      this.formState.days = course.days;
      // this.tempDateRange.push(dayjs(course.start_date, 'YYYY-MM-DD'));
      // this.tempDateRange.push(dayjs(course.end_date, 'YYYY-MM-DD'));
      
      this.formState.start_time = course.start_time;
      this.formState.end_time = course.end_time;
      this.tempStartTime = dayjs(course.start_time, 'HH:mm');
      this.tempEndTime = dayjs(course.end_time, 'HH:mm');
      
      // set lessions
      this.lessionsState = [];
      course.lessions.forEach((item, idx) => {
        // item.name = `課堂${idx+1}：${item.name}`
        const dateRes = DateUtil.formalizeDate(item.start_date);
        item.start_datetime_formated = dateRes;
        item.tempStartDate = undefined;

        this.lessionsState.push(item);
      });

      console.log(this.formState)
      console.log(this.lessionsState)
      
      this.step = 2;
    },

    async handleCancel() {
      this.$emit('cancel');
      
      // reset data
      this.resetForm();
      this.semesterOptions = await this.semesterStore.getOptions();
      this.step = 1;
    },
    async handleOk() {
      console.log(this.formState);
      console.log(this.lessionsState);

      const err = this._checkRequried();
      if (err) {
        message.error(err);
        return
      }

      /* Create course */
      this.loading = true;
      let courseId = undefined;

      try {
        const courseCreateResp = await this.api.v1.course.create({
          data: this.formState,
          auto_create_lessions: {
            auto_create_lessions: false
          }
        });
        courseId = courseCreateResp.data.result.id;
      } catch (error) {
        console.log(error);
        message.error('創建班級發生錯誤');
        
        this.handleCancel();
        this.loading = false; 
        return
      }

      /* Create lessions */
      for (let i=0; i<this.lessionsState.length; i++) {
        const item = this.lessionsState[i];

        const lessionData = {
          name: item.name,
          description: item.description,
          start_date: dayjs(item.tempStartDate).format('YYYY-MM-DD'),
          start_time: this.formState.start_time,
          homeworks: item.homeworks,
          videos: item.videos
        }

        await api.v1.lession.create({
          course_id: courseId,
          ...lessionData
        });
      }

      // try {
      //   await api.v1.lession.create({
      //     course_id: courseId,
      //     ...this.formState
      //   });
      //   console.log(this.formState)
      //   message.success('創建課堂成功');
      // } catch (error) {
      //   message.error('創建課堂發生錯誤');
      //   console.log(error);
      // }

      /* Join course's assistant & coach  */
      const joinAssistantParmas = {
        course_id: courseId,
        ids: this.formState.assistant_ids
      }
      const joinCoachParmas = {
        course_id: courseId,
        ids: this.formState.coach_ids
      }

      try {
        await this.api.v1.course.joinMembers('assistant', joinAssistantParmas);
        await this.api.v1.course.joinMembers('coach', joinCoachParmas);
        message.success('創建班級成功');
      } catch (error) {
        console.log(error);
        message.error('創建班級：加入助教及教練發生錯誤');

        this.handleCancel();
        this.loading = false; 
        return
      }

      this.resetForm();
      this.$emit('confirm');
      this.loading = false;
    },

    /* Private func */
    _checkRequried() {
      let err = '';
      
      if (!this.formState.name) {
        err = '請輸入班級名稱';
      }
      else if (!this.formState.description) {
        err = '請輸入班級簡介';
      }
      else if (!this.formState.classroom) {
        err = '請輸入教室名稱';
      }
      else if (!this.formState.start_date || !this.formState.end_date) {
        err = '請輸入開課日期區間';
      }
      else if (this.formState.days.length == 0) {
        err = '請輸入每週上課日';
      }
      else if (!this.formState.start_time) {
        err = '請輸入課堂開始時間';
      }
      else if (!this.formState.end_time) {
        err = '請輸入課堂結束時間';
      }

      for (let i=0; i<this.lessionsState.length; i++) {
        const item = this.lessionsState[i];
        if (!item.tempStartDate) {
          err = `請輸入 課堂${i+1} 的上課日期`;
          break;
        }
      }

      return err
    }
  }
})
</script>

<style lang="scss">
.create-course-from-template-modal {
  
}
</style>