<template>
  <a-layout-header class="layout-header">
    <a-row>
      <a-col :span="24">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>
        
        <div class="header-title">班級管理</div>
      </a-col>
    </a-row>
  </a-layout-header>

  <a-layout-content class="layout-content">
    <a-card class="app-channel-courses-page" :bordered="false">
      <div class="page__header">
        <a-row>
          <a-col :span="12">
            <a-select
              v-model:value="semesterValue"
              :options="semesterList"
              @change="changeSemester"
              :style="{ width: '120px', marginBottom: '20px' }"
            />
          </a-col>

          <a-col :span="12" align="right">
            <a-dropdown trigger="click">
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    @click="openCreateCourseModal"
                  >全新建立
                  </a-menu-item>
                  <a-menu-item
                    @click="openCreateCourseFromTemplateModal"
                  >從班級匯入
                  </a-menu-item>
                </a-menu>
              </template>

              <a-button
                v-if="authStore.info.role > 3"
                type="primary"
              >
                <template #icon><PlusOutlined /></template>新增班級
              </a-button>
            </a-dropdown>

            <!-- <a-button
              v-if="authStore.info.role > 3"
              @click="openCreateCourseModal"
              type="primary"
            >
              <template #icon><PlusOutlined /></template>新增班級
            </a-button> -->
          </a-col>
        </a-row>

        <a-tabs v-model:activeKey="tabValue" size="large">
          <a-tab-pane key="all" tab="全部班級"></a-tab-pane>
          <a-tab-pane key="upcoming" tab="即將開始"></a-tab-pane>
          <a-tab-pane key="active" tab="上課中"></a-tab-pane>
        </a-tabs>
      </div>

      <div class="page__body">
        <CourseList
          :data="filteredData"
          :loading="loading"
        />
      </div>
    </a-card>
  </a-layout-content>

  
  <CreateCourseModal
    :visible="createCourseModal.visible"
    @confirm="handleCreateCourseModalConfirm"
    @cancel="handleCreateCourseCancel"
  />

  <CreateCourseFromTemplateModal
    :visible="createCourseFromTemplateModal.visible"
    @confirm="handleCreateCourseFromTemplateModalConfirm"
    @cancel="handleCreateCourseFromTemplateCancel"
  />
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import { useSemesterStore } from '@/store/semester'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons-vue'
import api from '@/apis'
import CreateCourseModal from './components/CreateCourseModal.vue'
import CreateCourseFromTemplateModal from './components/CreateCourseFromTemplate.vue'
import CourseList from './components/CourseList.vue'

export default defineComponent({
  components: {
    CreateCourseModal,
    CreateCourseFromTemplateModal,
    CourseList,
    PlusOutlined
  },
  setup() {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const semesterStore = useSemesterStore();

    /* Data */
    const coursesData = ref([]);

    /* Status */
    const loading = ref(false);

    /* Year Select */
    const semesterValue = ref(semesterStore.currentSemesterYear);
    const semesterList = ref([]);

    /* Api */
    const getCoursesData = (async () => {
      coursesData.value = [];
      loading.value = true;
      
      let response = undefined;
      if (authStore.info.role == 4) {
        response = await api.v1.course.list({semester: semesterValue.value});
      } else {
        response = await api.v1.course.belongList(
          authStore.info.uid,
          {
            role: authStore.info.role,
            semester: semesterValue.value
          }
        );
      }

      console.log(response.data.result)

      // 統計數據 & 計算完成度 & 下一個上課日
      response.data.result.forEach(course => {
        // 統計數據
        let counter = {
          lession: course.lessions.length,
          image: 0,
          participant: course.users.length
        }

        let completeLessionCount = 0;
        let nextLession = {};
        course.lessions.forEach(lession => {
          counter.image += lession.images.length

          if (moment().isAfter(`${lession.start_date} ${course.end_time}`)) {
            completeLessionCount += 1;
          }

          if (Object.keys(nextLession).length == 0) {
            if (moment().isBefore(`${lession.start_date} ${course.end_time}`)) {
              nextLession = lession;
            }
          }
        });
        course.counter = counter;

        // 計算完成度
        course.completed = 0;
        if (course.lessions.length > 0) {
          course.completed = Math.round(completeLessionCount / course.lessions.length * 100);
        }
        
        // 下一個上課日
        course.upcomingDatetime = null;
        if (course.lessions.length > 0 && course.upcomingDatetime == null) {
          course.upcomingDatetime = `${nextLession.start_date} ${nextLession.start_time}`;
        }
      });

      
      coursesData.value = response.data.result;
      loading.value = false;

      console.log(coursesData.value)
    });

    /* Mounted */
    onMounted(async () => {
      semesterList.value = await semesterStore.getOptions();
      await getCoursesData();
    });

    return {
      /* Store */
      authStore,
      appStore,

      /* Tabs */
      tabValue: ref('all'), // all|upcoming|active

      /* Semester Options */
      semesterValue,
      semesterList,

      /* Data */
      loading,
      coursesData,

      /* Api */
      getCoursesData
    }
  },
  data() {
    return {
      /* Create Course Modal */
      createCourseModal: {
        visible: false
      },

      /* Create Course From Template Modal */
      createCourseFromTemplateModal: {
        visible: false
      }
    }
  },
  computed: {
    filteredData() {
      let data = [];

      if (this.tabValue == 'all') data = this.coursesData
      else if (this.tabValue == 'upcoming') {
        this.coursesData.forEach(item => {
          if (item.completed == 0) data.push(item);
        });
      }
      else if (this.tabValue == 'active') {
        this.coursesData.forEach(item => {
          if (item.completed > 0 && item.completed < 100) data.push(item);
        });
      }

      return data
    }
  },
  methods: {
    /* Semester */
    changeSemester() {
      this.getCoursesData();
    },
    
    /* Create Course Modal */
    openCreateCourseModal() {
      this.createCourseModal.visible = true;
    },
    handleCreateCourseModalConfirm() {
      this.createCourseModal.visible = false;
      this.getCoursesData();
    },
    handleCreateCourseCancel() {
      this.createCourseModal.visible = false;
    },

    /* Create Course From Template Modal */
    openCreateCourseFromTemplateModal() {
      this.createCourseFromTemplateModal.visible = true;
    },
    handleCreateCourseFromTemplateModalConfirm() {
      this.createCourseFromTemplateModal.visible = false;
      this.getCoursesData();
    },
    handleCreateCourseFromTemplateCancel() {
      this.createCourseFromTemplateModal.visible = false;
    }
  }
})
</script>

<style lang="scss">
.app-channel-courses-page {
  .page__header {
    margin-bottom: 12px;
  }
}
</style>